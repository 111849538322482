<template>
  <nav>
    <!-- <button class="ZTmodel" @click="build" v-if="store.state.isDark==1">变白</button>
    <button class="ZTmodel" @click="build" v-if="store.state.isDark==0">变黑</button> -->
    <!-- <div @click="changeLanguage('zh')" v-if="i18n.locale.value=='en'" class="i18Btn">中</div>
    <div @click="changeLanguage('en')" v-if="i18n.locale.value=='zh'"  class="i18Btn">A</div> -->
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </nav>
  <router-view />
  <TABBAR></TABBAR>
</template>
<script setup>
import Ethereum from "@/api/wallet/ethereum";
import Contract from "@/api/wallet/contract";
  import { useI18n } from 'vue-i18n'
  import TABBAR from '@/components/tabbar.vue'
  import { useStore } from 'vuex';
  import { onMounted, ref, onUnmounted, nextTick } from 'vue';
import { userStore } from "./stores/modules/user";
import { showToast } from "vant";

import { useRouter,useRoute } from "vue-router";
import { userInfo } from "./api/api";
  const store = useStore()
    
  const router = useRouter()
  const route = useRoute()
  const i18n = useI18n()
  const changeLanguage = (code) => {
    i18n.locale.value = code
    localStorage.setItem('i18n', code)
  }


  const getUserInfo =(params)=>{ //用户信息
  userInfo(params).then(res=>{
    if(res && res.user){
      userStore().updateInfo(res.user);
    localStorage.setItem('userData',JSON.stringify(res.user))
    }
    // window.location.reload()
  })
}

  const onConnect = async () => {
  await Ethereum.init();
  await Contract.init();
  if (!userStore().sign) {
    await Contract.signMessage().then((res)=>{
      getUserInfo({ parentAddress: route.query.code })

    }).catch((e)=>{
      showToast(e.message)
    })
    console.log(route.query)
  }
};

  onMounted(() => {
    if (localStorage.getItem('isDark')) {
      store.state.isDark = localStorage.getItem('isDark')
      if (store.state.isDark == 1) {
        document.body.setAttribute('class', 'dark');
      } else if(store.state.isDark == 0) {
        document.body.setAttribute('class', 'light');
      }
    }else{
        document.body.setAttribute('class', 'dark');
        localStorage.setItem('isDark',1)
        store.state.isDark = 1
    }
    if(localStorage.getItem('i18n')=='zh'){
      i18n.locale.value = 'zh'
    }else if(localStorage.getItem('i18n')=='en'){
      i18n.locale.value = 'en'
    }else{
      i18n.locale.value = 'zh'
    }
    if(localStorage.getItem('activeTabKey')){
      store.state.activeTabKey = localStorage.getItem('activeTabKey')
    }
    
  });

  nextTick(()=>{
    onConnect()
  })

  const build = () => {
    console.log(store.state.isDark)
    if (store.state.isDark==1) {
      document.body.setAttribute('class', 'light');
      store.state.isDark = 0
      localStorage.setItem('isDark', 0)
    } else {
      document.body.setAttribute('class', 'dark');
      store.state.isDark = 1
      localStorage.setItem('isDark', 1)
    }
    location.reload();
  }
</script>

<style>
  @import './assets/css/color.scss';
  .i18Btn{
    position: fixed;
    right: 0;
    height:25px;
    width: 40px;
    border-radius: 5px 0 0 5px;
    background: var(--i18BG);
    color: var(--textColor);
    top: 60px;
    line-height: 25PX;
    text-align: center;
    font-size: 13px;
    z-index: 9999;
  }
  .ZTmodel{
    position: fixed;
    top: 80%;
  }
</style>