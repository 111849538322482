import axios from "axios";
import { showToast } from "vant";
import { baseURL } from "./url";
import { userStore } from "@/stores/modules/user";
import Contract from "./wallet/contract";
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
var isShowSign = false;
// 创建 axios 请求实例
const serviceAxios = axios.create({
  baseURL: baseURL, // 基础请求地址
  timeout: 10000, // 请求超时设置
  withCredentials: false, // 跨域请求是否需要携带 cookie
});

// 创建请求拦截
serviceAxios.interceptors.request.use(
  async (config) => {
    // 如果开启 token 认证

    // 设置请求头
    if (!config.headers["content-type"]) {
      // 如果没有设置请求头
      if (config.method === "post") {
        config.headers["content-type"] = "application/json"; // post 请求
        config.data = config.data;
      } else {
        config.headers["content-type"] = "application/json"; // 默认类型
      }
    }
    config.headers.Address = userStore().address;
    config.headers.Authorization = userStore().sign;
    console.log("请求配置", config);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 创建响应拦截
serviceAxios.interceptors.response.use(
  (res) => {
    let data = res.data;

    // 处理自己的业务逻辑，比如判断 token 是否过期等等
    // 代码块
    if (data.data) {
      return data.data;
    }
    return data;
  },
  (error) => {
    let message = "";
    if (error && error.response) {
      switch (error.response.status) {
        case 500:
          showToast("网络异常");
          break;
        case 501:
          showToast("网络异常");
          break;
        case 502:
          showToast("网络异常");
          break;
        case 503:
          showToast("网络异常");
          break;
        case 504:
          showToast("网络异常");
          break;
        case 505:
          showToast("网络异常");
          break;
          case 401:
            showToast("请先完成签名");
            break;
        default:
          showToast("网络异常");
          return error.response.data;
      }
    }
  }
);
export default serviceAxios;
