<template>
    <div class="tabbar" v-if="showTable()">
        <div :class="store.state.activeTabKey == 1?'activeBTNMODEL':'BTNMODEL'" @click="tapTab(1,'/')"> 
            <img src="../assets/tabbar/shouye1.png" class="icon" alt="" v-if="store.state.activeTabKey!=1">
            <img src="../assets/tabbar/shouye2.png" class="icon" alt="" v-if="store.state.activeTabKey==1&& isDark==0">
            <img src="../assets/tabbar/shouye3.png" class="icon" alt="" v-if="store.state.activeTabKey==1&& isDark==1">
            <div> {{ $t("shouye") }}</div>
        </div>
        <div :class="store.state.activeTabKey == 2?'activeBTNMODEL':'BTNMODEL'" @click="tapTab(2,'/share')">
          <img src="../assets/tabbar/fenxiang1.png" class="icon" alt="" v-if="store.state.activeTabKey!=2">
          <img src="../assets/tabbar/fenxiang2.png" class="icon" alt="" v-if="store.state.activeTabKey==2&& isDark==0">
          <img src="../assets/tabbar/fenxiang3.png" class="icon" alt="" v-if="store.state.activeTabKey==2&& isDark==1">
          <div>{{ $t("fenxiang") }}</div>
        </div>
        <div @click="tapTab(3,'/trade')" class="BTNMODEL jyCss">
          <img src="../assets/tabbar/jiaoyi3.png" class="icon" alt="" >
        </div>
        <div @click="tapTab(3,'/trade')" :class="store.state.activeTabKey == 3?'activeBTNMODEL':'BTNMODEL'">
          <img src="../assets/tabbar/jiaoyi.png" class="icon" style="opacity: 0;" alt="">
          <div>{{ $t("jiaoyi") }}</div>
        </div>
        <div :class="store.state.activeTabKey == 4?'activeBTNMODEL':'BTNMODEL'" @click="tapTab(4,'/team')">
          <img src="../assets/tabbar/tuandui1.png" class="icon" alt="" v-if="store.state.activeTabKey!=4">
          <img src="../assets/tabbar/tuandui2.png" class="icon" alt="" v-if="store.state.activeTabKey==4&& isDark==0">
          <img src="../assets/tabbar/tuandui3.png" class="icon" alt="" v-if="store.state.activeTabKey==4&& isDark==1">
          <div>{{ $t("tuandui") }}</div>
        </div>
        <div :class="store.state.activeTabKey == 5?'activeBTNMODEL':'BTNMODEL'" @click="tapTab(5,'/asset')">
          <img src="../assets/tabbar/zichan1.png" class="icon" alt="" v-if="store.state.activeTabKey!=5">
          <img src="../assets/tabbar/zichan2.png" class="icon" alt="" v-if="store.state.activeTabKey==5&& isDark==0">
          <img src="../assets/tabbar/zichan3.png" class="icon" alt="" v-if="store.state.activeTabKey==5&& isDark==1">
          <div>{{ $t("zichan") }}</div>
        </div>
    </div>
</template>
<script setup>
 import { useRouter  ,useRoute} from 'vue-router'
  import {ref,onMounted} from 'vue'
  import { useStore } from 'vuex';
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
 
  const isDark = ref(0)
  const tapTab = (e,path)=>{
    if (e== 3 ){
      if (store.state.activeTabKey != 3){
        path += "?symbol=XAUUSD"
      }else{
        return
      }
    }
    store.state.activeTabKey = e
    router.push(path)
    
  }
  onMounted(()=>{
    if(localStorage.getItem('isDark')){
      isDark.value = localStorage.getItem('isDark')
    }
    console.log(route.path)
  })
  const showTable = ()=>{
    let paths=['/','/asset','/team','/trade','/share']
    if(paths.some(item=>{return store.state.path==item})){
      return true
    }else {
      return false
    }
  }
</script>
<style scoped lang="less">
 .tabbar{
    display: flex;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    padding: 10px 0 20px;
    bottom: 0;
    color:var( --tabbarText);
    background: var(--tabbarBG);
    align-items: center;
  }
  .BTNMODEL{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    line-height: 11px;
   
  }
  .activeBTNMODEL{
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--activeTabbarText);
    font-size: 11px;
    line-height: 11px;

  }
  .icon{
      width: 20px;
      height: 20px;
      margin-bottom: 10px;
    }
    .jyCss{
    position: absolute;
    border-radius: 50%;
    top: -15px;
    width: 50px;
    height: 50px;
    background: var(--jyBG);
    .icon{
      width: 30px;
      height: 30px;
      margin:0
    }
    }
</style>