import {API_CONFIG} from './abi'
import { DAMNU_ENABLE } from "@/api/interface/user";
// const IS_USE_NETWORK = "";

let USDT_CONTRACT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'
let CONTRACT_ADDRESS = '0x5524f9277D44244B61f5e046586e3d42a900A2ac';
let NETWORK = {
    chainId: '0x38', //56
    rpcUrls: ['https://rpc.ankr.com/bsc'],
    chainName: 'Binance Smart Chain Mainnet',
    blockExplorerUrls: ['https://bscscan.com'],
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    }
}
    //TEST
//     USDT_CONTRACT_ADDRESS = '0x1f3cF80e63bF2e338f74707b09712767eaC6bd00'

    
//   let  NETWORK = {
//         chainId: '0x61', //97
//         rpcUrls: ['https://bsc-testnet.public.blastapi.io'],
//         chainName: 'Binance Smart Chain Testnet',
//         blockExplorerUrls: ['https://testnet.bscscan.com'],
//         nativeCurrency: {
//             name: 'BNB',
//             symbol: 'tBnB',
//             decimals: 18
//         }
//     }

export {
    USDT_CONTRACT_ADDRESS,
    CONTRACT_ADDRESS,
    NETWORK,
    API_CONFIG
}



export const ERROR_INFO:DAMNU_ENABLE = {
    '4001': '用戶拒絕鏈接',
    '-32602': '參數錯誤',
    '-32603': '系統錯誤',
    '-32002': '請打開錢包',
    ACCOUNT_DIS: '賬戶已斷開',
    SYSTEM_ERROR: '系統錯誤'
}
