<template>
    <div class="prodictsDetailModel">
       
        <div class="headerTitle" >
            <img src="../../assets/fanhuijiantou.png" class="goBlack" alt="" v-if="isDark==1" @click="goBlack">
            <img src="../../assets/fanhuijiantou1.png" class="goBlack" alt="" v-if="isDark==0"  @click="goBlack">
            {{ $t("cpxxzx") }}
        </div>
        <div style="height: 20px;"></div>
        <!-- <div class="advertising">
            人工智能图片占位
        </div> -->
        <div class="profitModel">
            <div class=" m_b_10 font14" style="color: var(--lcitemTitle);">{{$t('lc')}}{{props.Details.day}}天</div>
            <div class="detail">
                <div class="leftText">{{ $t('gmjefw') }}</div>
                <div class="rightText">{{props.Details.minAmount}}</div>
            </div>
            <!-- <div class="detail">
                <div class="leftText">{{ $t('rsr') }}</div>
                <div class="rightText">3.1000%</div>
            </div> -->
            <!-- <div class="detail">
                <div class="leftText">{{ $t('kyye') }}</div>
                <div class="rightText">100</div>
            </div> -->
            <div class="detail">
                <div class="leftText">{{ $t('rlv') }}</div>
                <div class="rightText">{{props.Details.rate *100}}%</div>
            </div>
            <!-- <div class="detail">
                <div class="leftText">{{ $t('cycs') }}</div>
                <div class="rightText">5/40</div>
            </div> -->
            <div class=" m_b_10 m_t_30 font14" style="color: var(--lcitemTitle);">{{ $t('tgje') }}</div>
            <input type="number" class="inputClss" v-model="prams.financeAmount" :placeholder="$t('qsr')" >
            <VanButton class="kyBTN" @click="tapQR">{{ $t('sqkt') }}</VanButton>
            <p class="ts">1. {{ $t('ts2') }}</p>
            <p class="ts" >2. {{ $t('ts3') }}</p>
            <p class="ts">3. {{ $t('ts4') }}</p>
            <p class="ts">4. {{ $t('ts1') }}</p>
            
        </div>
       
    </div>
</template>
<script setup>
import { ref, onMounted ,defineEmits,defineProps,reactive} from 'vue'
import { useRouter  } from 'vue-router'
import { financejoin,userInfo} from '@/api/api'
import { showToast } from 'vant';
import i18n from '@/i18/index';
const isDark = ref(0)
const router = useRouter()
const props = defineProps(['Details'])
const emits= defineEmits(['goBlack'])
const activebtn = ref(2)
const Info = ref({})
const prams = reactive({
    financeCode: "", 
financeAmount: '' 
})
onMounted(()=>{
    if(localStorage.getItem('isDark')){
      isDark.value = localStorage.getItem('isDark')
    }
    if(props.Details){
        prams.financeCode = props.Details.code
        prams.financeAmount = props.Details.minAmount
    }
    getuserInfo()
})
const getuserInfo = () => {
  userInfo().then(res => {
    console.log(res)
    if (res&&res.user) {
      Info.value = res.user
      localStorage.setItem('userData', JSON.stringify(res.user))
    }
  })
}
const tapQR = ()=>{
    console.log(Info.value)
    if(Info.value.usdtAmount<prams.financeAmount){
        showToast(i18n.global.t('yebz'))
        return  
    }
    if(prams.financeAmount<props.Details.minAmount){
        showToast(i18n.global.t('bnxygmje'))
        return
    }
    financejoin(prams).then(res=>{
        console.log(res)
        if(res &&res.code == 20000){
            showToast(res.message)
            return
        }
        if(res && res.code==10000){
        showToast(i18n.global.t('sqcg'))
        // showToast(res.message)
        // emits('change',true)
        emits('goBlack')
      }
    })
}
const goBlack = ()=>{
    emits('goBlack')
}

</script>
<style scoped lang="less">
.prodictsDetailModel {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: var(--backgroud);
    left: 0;
    padding: 10px;
    height: 100vh;
    overflow: auto;
    .headerTitle {
        width: 100%;
        text-align: center;
        height: 28px;
        line-height: 28px;
        color: var( --textColor);
        position: relative;
    }
    .goBlack{
        top:50%;
        transform: translate(0, -50%);
         left: 10px;
        width: 20px;
        height: 20px;
        position: absolute;

    }
    .advertising {
        margin: 15px 0;
        height: 80px;
        background: var(--homeCardBg);
        border-radius: 5px;
    }

    .profitModel {
        position: relative;
        background: var(--homeCardBg);
        padding: 15px 15px 25px;
        border-radius: 5px;
        .detail{
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .leftText{
                font-size: 12px;
                line-height: 12px;
            }
            .rightText{
                font-size: 13px;
                color: var(--lcitemTitle);
            }
        }
        .inputClss{
            width: 100%;
            box-sizing: border-box;
            height: 35px;
            background-color: var(--inputBg);
            border-radius: 5px;
            border: 0;
            color: var(--inputText);
        }
        .kyBTN{
            background: var(--ktbtnbg);
            color: var(--ktbtntext);
            text-align: center;
            border-radius: 20px;
            font-size: 14px;
            line-height: 35px;
            height: 35px;
            width: 100%;
            box-sizing: border-box;
            margin-top: 20px;
            margin-bottom: 25px;
        }
        .ts{
            color: #6f7377;
            font-size: 14px;
            line-height: 14px;
            margin: 5px 0;
        }
    }

}


</style>