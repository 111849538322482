<template>
  <div class="home"> 
    <div class="homeHeader">
      <div class="leftModel">
        <img src="@/assets/logo.png" class="userLogo" alt="">
        <!-- <div class="font12">这是logo</div> -->
      </div>

     
      <div class="rightModel font12">
        <div class="yyModel">
          <img :src="activeYYboj.url" class="yuyanImg" @click="showXZYY=!showXZYY" alt="">
          <div class="select" v-if="showXZYY">
            <div class="item"  v-for="(item,i) in guojiaList" :key="i" @click="selectYY(item)">
              <img :src="item.url" class="logo" alt="">
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
        <div>{{ formatAddress(user.address) }}</div>
        <div class="settingModel">
        <div class="rightBtn ">
            
            <van-switch
              v-model="isDark"
              size="20px"
              @change="chaneg"
              active-color="#073c71"
              inactive-color="#dcdee0"
              active-value="1"
              inactive-value="0"
            />
            <img src="@/assets/taiyang.png" class="icons" alt="" v-if="isDark=='0'">
            <img src="@/assets/a-yueliang.png" class="icons" alt="" v-if="isDark=='1'">
          </div>

      </div>
        <!-- <img src="@/assets/liebiao.png"  @click="showSetting = true" class="liebiao" alt="" v-if="isDark == 1">
        <img src="@/assets/liebiao1.png"  @click="showSetting = true" class="liebiao" alt="" v-if="isDark == 0"> -->
      </div>
    </div>
    <div class="homeSwipeModel">
      <van-swipe class="my-swipe" :autoplay="10000" indicator-color="white">
        <van-swipe-item>
          <img style="height:150px" src="@/assets/zs1.jpg" alt="" @click="showImagePreview([require('@/assets/zs1.jpg')]);">
        </van-swipe-item>
        <van-swipe-item>
          <img style="height:150px"  src="@/assets/zs1.jpg" alt="" @click="showImagePreview([require('@/assets/zs2.jpg')]);">
        </van-swipe-item>
       
      </van-swipe>
    </div>
    <div class="homeNoticeModel">
      <van-notice-bar class="notice" left-icon="volume-o" :scrollable="false" @click="goNotList">
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :touchable="false" :show-indicators="false">
          <van-swipe-item v-for="(item,i) in NoticesList">{{item.title}}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>

    </div>
    <van-popup v-model:show="NotListShow" position="bottom" :style="{ height: '100vh' }">
      <NotList :NoticesList="NoticesList" @goBlack="NotListShow=false"></NotList>
    </van-popup>
    
    <div class="homeSelectTab font12">
      <div @click="tapSelectTab(1)" :class="activeTab == 1 ? 'activeTab tab' : 'tab'">{{ $t("xw") }}</div>
      <div @click="tapSelectTab(2)" :class="activeTab == 2 ? 'activeTab tab' : 'tab'">{{ $t("qq") }}</div>
      <div @click="tapSelectTab(3)" :class="activeTab == 3 ? 'activeTab tab' : 'tab'">{{ $t("lc") }}</div>
      <div @click="tapSelectTab(4)" :class="activeTab == 4 ? 'activeTab tab' : 'tab'">{{ $t("kf") }}</div>
      <div @click="tapSelectTab(5)" :class="activeTab == 5 ? 'activeTab tab' : 'tab'">{{ $t("jd") }}</div>
      <div @click="tapSelectTab(6)" :class="activeTab == 6 ? 'activeTab tab' : 'tab'">{{ $t("gd") }}</div>
    </div>
    <div v-if="activeTab == 1" class="homeCardModel optionModel" style="width:100%;height:calc(100vh - 350px)">
  
      <div v-for="item in news" class="item" @click="tapOptionItem(item.Link)">
         <div class="text" style="    display: flex;
    flex-direction: column;
    justify-content: space-between;">
          <div class="font13 m_b_20">{{ item.Title }}</div>
          <div class="content">{{ item.PubDate }}</div>
        </div>
        <img :src="item.Enclosure.URL" class="img" alt="">
      </div>
     
    </div>

    <div class="homeCardModel scroll"  v-if="activeTab == 2">
      <div class="Item" v-for="(item, i) in optionList" :key="i" @click="gotrade(item)">
        <div class="left font12">
          <img :src="getIco(item.symbol)" class="icon" alt="">
          <span>{{ item.symbol }}</span>
        </div>
        <div class="right">
          <div class="USD">{{ formatPrice(item.price) }}</div>
          <div :class="item.diff > 0 ? 'rise' : item.diff <0 ? 'drop':''">
            <span class="m_r_20 font12">{{ formatPrice(item.diff) }}</span>
            <span class="font12 "  :class="item.diff > 0 ? 'riseJT' :item.diff <0 ? 'dropJT':''">{{ formatPrice(item.change,2) }}%</span>
          </div>
        </div>
      </div>
      <!-- <div class="Item">
        <div class="left font12">
          <img src="" class="icon" alt="">
          <span>银</span>
        </div>
        <div class="right">
          <div class="USD">USD 2391.6201</div>
          <div class="drop">
            <span class="m_r_20 font12">34.17017</span>
            <span class="font12 dropJT">1.447%</span>
          </div>
        </div>
      </div> -->
    </div>

    <van-popup v-model:show="showFinacial" position="bottom" :style="{ height: '100vh' }">
      <Finacial @goBlack="closeFinacial"></Finacial>
    </van-popup>

    <div v-if="activeTab == 4" class="homeCardModel emilModel">
      <div>
        <img src="@/assets/logo.png" style="width: 150px;" alt="">
      </div>
      <div class="font12 emil">{{ $t("emil") }}：VTmarketscx@outlook.com </div>
      <div class="copyEmil font12" @click="copyToClipboard">{{ $t("copy") }}</div>
    </div>
  </div>
  <div class="wkf " v-if="activeTab == 5||activeTab == 6">
               {{$t('wkf')}}
            </div>
  <van-popup
      v-model:show="showSetting"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="settingModel">
        <!-- <div class="item" @click="updatePassword">
          <span>{{ $t("upPassword") }}</span>
          <img
            src="../assets/jiantouxi.png"
            class="icon"
            alt=""
            v-if="isDark == 1"
          />
          <img
            src="../assets/jiantouxi1.png"
            class="icon"
            alt=""
            v-if="isDark == 0"
          />
        </div> -->
        <div class="item">
          <span>{{ $t("mode") }}</span>
        
          <div class="rightBtn">
            <img src="@/assets/taiyang.png" class="icons" alt="">
            <van-switch
              v-model="isDark"
              size="20px"
              @change="chaneg"
              active-color="#073c71"
              inactive-color="#dcdee0"
              active-value="1"
              inactive-value="0"
            />
            <img src="@/assets/a-yueliang.png" class="icons" alt="">
          </div>
        </div>
        <!-- <div class="fixedBtn2">
          {{ $t("tcdl") }}
        </div> -->
      </div>
    </van-popup>
</template>

<script setup>
import { ticker ,notices,userInfo} from "@/api/api"
import Finacial from './components/finacial.vue'
import NotList from './components/NotList.vue'
import { Swipe, SwipeItem, NoticeBar, Popup, showToast ,showImagePreview } from 'vant';
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex';
// import { Option } from '../assets/js/option.js'

import { userStore } from "@/stores/modules/user";
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { formatAddress, formatPrice, getIco } from "@/api/kit";
import axios from 'axios'
const news = ref([])
const showSetting = ref(false)
const i18n = useI18n()
const guojiaList = ref([
  {title:'中文',url:require('@/assets/gq/cn.png'),value:'zh'},
  {title:'English',url:require('@/assets/gq/gb.png'),value:'en'},
  {title:'日本語',url:require('@/assets/gq/jp.png'),value:'ja'},
  {title:'한국어',url:require('@/assets/gq/kr.png'),value:'ko'},
  // {title:'ภาษาไทย',url:require('@/assets/gq/th.png'),value:'th'},
  // {title:'Tiếng Việt',url:require('@/assets/gq/vn.png'),value:'vn'},
])
const router = useRouter()
const route = useRoute()
const store = useStore()
const isDark = ref(0)
const activeTab = ref(1)
const showFinacial = ref(false)
const optionList = ref([])
const NoticesList = ref([])
const userInfoData = ref({})
const showXZYY = ref(false)
const NotListShow = ref(false)
const user = userStore()
const activeYYboj = ref( {title:'中文',url:require('@/assets/gq/cn.png'),value:'zh'},)


onMounted(() => {
  if(localStorage.getItem('i18n')){
      guojiaList.value.forEach(item=>{
        if(item.value == localStorage.getItem('i18n')){
          activeYYboj.value = item
        }
      })
    }else{
      activeYYboj.value=guojiaList.value[0]
    }
   window.addEventListener('scroll', handleScroll);
  if (localStorage.getItem('isDark')) {
    isDark.value = localStorage.getItem('isDark')
  }
  getNotices()
  getNews()
})
const chaneg = (e) => {
  0;
  console.log(e);
  if (e == 0) {
    document.body.setAttribute("class", "light");
    store.state.isDark = 0;
    localStorage.setItem("isDark", 0);
  } else {
    document.body.setAttribute("class", "dark");
    store.state.isDark = 1;
    localStorage.setItem("isDark", 1);
  }
  localStorage.setItem("activeTabKey", 1);
  location.reload();
};
const getNews=()=>{
  axios.get('/api/news')
          .then(response => {
             // 在这里处理获取到的 RSS 数据
             news.value.push(...response.data.data)
           })
          .catch(error => {
             console.error('Error fetching RSS data:', error);
           });

}

const gotrade = (item)=>{
  console.log("gotrade",item.symbol)
  store.state.activeTabKey = 3
    // localStorage.setItem('activeTabKey',3)
    localStorage.setItem('symbol',JSON.stringify(item))
  router.push({name:'trade',query:{"symbol":item.symbol}})
}
const selectYY = (item)=>{
  showXZYY.value = false
  console.log(item)
  activeYYboj.value = item
  i18n.locale.value = item.value
  localStorage.setItem('i18n', item.value)
}
const getNotices =()=>{ //滚动消息
  notices({size:1}).then(res=>{
    if(res &&  res.list){
      NoticesList.value = res.list
    }
    
  })
}
const goNotList = ()=>{
  NotListShow.value = true
}
const tapSelectTab = (e) => { //
  activeTab.value = e
  if (e == 3) {
    showFinacial.value = true
  }
  if (e == 2) {
    getTicker()
  }
}
const getTicker=()=>{ // active2请求
  ticker().then(res => {
      if (res &&res.length > 0) {
        optionList.value = res
      }
    })
}

const closeFinacial = () => {
  activeTab.value = 1
  showFinacial.value = false
  console.log(123)
}
const escape = (item) => {
  if (item) {
    return JSON.parse(item)
  }
}
const tapOptionItem = (url) => {
  window.open(url)

}
async function copyToClipboard() {
  try {
    const text = 'VTmarketscx@outlook.com'
    // 添加一个input元素放置需要的文本内容
    const input = document.createElement('input')
    input.value = text
    document.body.appendChild(input)
    // 选中并复制文本到剪切板
    input.select()
    document.execCommand('copy')
    // 移除input元素
    document.body.removeChild(input)
    showToast('已复制到剪切板')

  } catch (error) {
    console.log(error)
    showToast('复制失败');
  }
}
const handleScroll = () => {
      const clientHeight = document.documentElement.clientHeight;
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log('Reached bottom');
        if(activeTab.value == 2){
          getTicker()
        }
      }
    };
</script>
<style scoped lang="less">
.home {
  padding: 10px;
  height: calc(100% - 80px);
  overflow: auto;
  padding-bottom: 80px;
}

.homeHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rightModel {
    display: flex;
    align-items: center;
  }

  .leftModel {
    display: flex;
    align-items: center;
  }

  .userLogo {
    width: 30px;
    height: 30px;
    background: #fff;
    margin-right: 5px
  }

  .liebiao {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}

.homeSwipeModel {
  border-radius: 5px;
  width: 100%;
  margin: 10px 0;

  .my-swipe .van-swipe-item {
    color: #fff;
    border-radius: 5px;
    font-size: 20px;
  
    text-align: center;
    background-color:var(--swipebg)

  }
}

.homeNoticeModel {
  margin: 15px 0;
  border-radius: 15px !important;
  overflow: hidden;

  .notice {
    background: var(--noticeBg) !important;
    color: var(--textColor) !important
  }

  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
}

.homeSelectTab {
  display: flex;

  .tab {
    margin-right: 20px;
  }

  .activeTab {
    color: var(--activeTabbarText);
    position: relative;
  }

  .activeTab:after {
    content: '';
    position: absolute;
    width: 25px;
    border-radius: 5px;
    height: 3px;
    background: var(--activeAfterBg);
    bottom: -10px;
    left: 50%;
    transform: translate(-50%);
  }
}

.optionModel {

  .item {
    justify-content: space-between;
    display: flex;
    margin-bottom: 10px;
    background: var(--homeCardBg);
    padding: 10px;
    border-radius: 5px;
  }

  .title {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* 显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .content {
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /* 显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .text {
    // width: calc(100% - 100px);
  }

  .img {
    width: 80px;
    height: 80px;
    margin: 5px 0;
    border-radius: 5px;
  }
}
.scroll{
  height: calc(100vh - 380px);
    overflow: auto;
  }
.homeCardModel {
  margin: 25px 0 15px;
  
  .Item {
    margin-bottom: 15px;
    background: var(--homeCardBg);
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-radius: 5px;

    .left {
      align-items: center;
      display: flex;

      .icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px
      }
    }

    .right {
      text-align: end;

      .USD {
        color: var(--USDText);
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px
      }

    }
  }
}

.emilModel {
  // background: var(--KFBG);
  margin: 15px auto;
  text-align: center;
  padding: 20px 0;
  height: calc(100vh - 450px);
  background-color: white;

  .emil {
    margin: 20px auto;
    text-align: center;
  }
  img{
    width: 100%;
  }

  .copyEmil {
    margin-top: 50px;
    padding: 5px;
    border-radius: 5px;
    width: 80px;
    background: var(--copyBtn);
    color: var(--activeTabbarText);
    margin: 0 auto;
  }
}
.settingModel {
    background: var(--backgroud);
    // padding: 15px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    .item {
      border-bottom: 1px solid var(--homeCardBg);
      padding: 10px 0;
      margin: 0 0 10px;
      font-size: 13px;
      line-height: 13px;
      color: var(--tradeGL);
      display: flex;
      align-content: center;
      justify-content: space-between;

      .rightBtn {
        display: flex;
        align-items: center;

        font-size: 10px;
      }

      .icon {
        width: 10px;
        height: 10px;
      }
    }
    .icons{
  width: 20px;
  margin: 0 10px;
}
  }
</style>