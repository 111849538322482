import { ethers } from 'ethers';
import { defineStore } from 'pinia'
import { ref } from 'vue';
import { IUserInfo } from '@/api/interface/user';
  
export const userStore = defineStore('user',
  () => {

    const address = ref("");
    const sign = ref("");
    const info = ref({})


    function updateAddress(newVal:string) {
      // address.value = ethers.getAddress(newVal) || '0xa38433265062F1F73c0A90F2FEa408f2Efd1a569'
      // address.value = '0x5276005B39264287c805924c89861C1Cc95473DC' || ethers.getAddress(newVal);
      // address.value = '0x6Ef5583903e6E83211c47c236A7B57D2F9373647' || ethers.getAddress(newVal);//顶号
      // 这个号0xB81bFdDb5A5d0b9c67ABC1E3a3058459dF7A506c的前端邀请人前端显示成固定的  0x9A7a567E1937333557E6BF894A71e419d4481244 这个地址显示
      // address.value = '0xB81bFdDb5A5d0b9c67ABC1E3a3058459dF7A506c' || ethers.getAddress(newVal);
      address.value = ethers.getAddress(newVal);
    }
    function updateSign(newVal:string) {
      sign.value = newVal;
    }
    function updateInfo(newVal: IUserInfo) {
      info.value = newVal;
    }
    return { address, sign, info,  updateAddress, updateSign, updateInfo }
  }, {
  persist: {
    storage: sessionStorage,
  },
}
)

