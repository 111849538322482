
// i18n.js
import { createI18n,I18nOptions } from 'vue-i18n';
import zh from "./zh.json";
import en from './en.json'
import ja from './ja.json'
import ko from './ko.json'
const messages: any = {
 en:en,
  zh:zh,
  ko:ko,
  ja:ja,

} as I18nOptions['messages'];
const locale = 'en'
const i18n = createI18n({
  locale: locale,
  fallbackLocale: 'en',
  legacy: false,
  globalInjection: true,
  messages,
});

export default i18n;