<template>
  <div class="orderhistoryModel">

    <div class="headerTitle">
      <img src="../../assets/fanhuijiantou.png" class="goBlack" alt="" v-if="isDark == 1" @click="goBlack">
      <img src="../../assets/fanhuijiantou1.png" class="goBlack" alt="" v-if="isDark == 0" @click="goBlack">
      {{ $t("gmlclb") }}
    </div>
    <!-- <div style="height: 20px;"></div> -->
    <!-- <div class="orderSelectTab font12">
            <div @click="tapSelectTab(1)" :class="activeTab==1?'activeTab tab':'tab'">{{ $t("jxz") }}</div>
            <div @click="tapSelectTab(2)" :class="activeTab==2?'activeTab tab':'tab'">{{ $t("ydq") }}</div>
            <div @click="tapSelectTab(3)" :class="activeTab==3?'activeTab tab':'tab'">{{ $t("ysh") }}</div>
          </div> -->
    <div class="orderScroll" ref="scrollContainer">
      <div class="orderModel" v-for="(item, i) in list" :key="i" v-if="total != 0">
        <span class="day">{{ item.financeDay }}</span>
        <div>
          <div class="title">{{ item.minAmount }}</div>
          <div class="font12 m_l_10 m_b_10">
            {{ $t('lcddh') }}
            <span class="font13 m_l_10 m_b_10 ">{{ item.code   }} </span>
          </div>
          <div class="font12 m_l_10 m_b_10">
            {{ $t('gzrsy') }}
            <span class="font13 m_l_10 m_b_10 ">{{ formatPrice(item.financeRate * 100 )}} %</span>
          </div>
          <div class="font12 m_l_10 m_b_10">
            {{ $t('yjrsy') }}
            <span class="font13 m_l_10 m_b_10 ">{{ formatPrice(item.financeAmount * (item.financeRate), 2) }}$ </span>
          </div>
          <div class="font12 m_l_10 m_b_10">
            {{ $t('kssy') }}
            <span class="m_l_10 m_b_10">{{ formatDate(item.startAt) }}</span>
          </div>
          <div class="font12 m_l_10 m_b_10" >
            {{ $t('jssy') }}
            <span class="m_l_10 m_b_10">{{ formatDate(item.endAt) }}</span>
            <div class="tagdanger"   plain v-if="showVantag(item.endAt)">{{ $t('ydq') }}</div>
          </div>
          <div class="font12 m_l_10 m_b_10" v-if="item.repeatEndAt>0">
            {{ $t('jsfl') }}
            <span class="m_l_10 m_b_10">{{ formatDate(item.repeatEndAt) }}</span>
          
                  </div>
          <div class="font12 m_l_10 m_b_10">
            {{ $t('sffl') }} <span class=" m_l_10 m_b_10 ">{{ item.repeatEndAt ? $t('yes') : $t('no') }}</span></div>
        </div>
       
        <div class="gmlcje rise"> {{ formatPrice(item.financeAmount+item.repeatAmount,2) || 0 }}$</div>
        <div class="flBTN" v-if="item.repeatEndAt == 0 && !showVantag(item.endAt)" @click.stop="tapFL(item)">{{ $t('kqfl') }}</div>
      </div>
      <div class="noData " v-if="total == 0">
        {{ $t('nodata') }}
      </div>
    </div>
    <van-dialog v-model:show="showfl" :showConfirmButton="false" :showCancelButton="false" show-cancel-button>
      <van-date-picker v-model="currentDate" :min-date="minDate" :max-date="maxDate" @confirm="confirm"
        @cancel="showfl = false" />
    </van-dialog>
    <OrderDetail v-if="orderDetailShow" :detailsRow="detailsRow" @goBlack="closeOrderDetails"></OrderDetail>
  </div>
</template>
<script setup>
import i18n from '@/i18/index';
import { DatePicker, showToast } from 'vant';
import { ref, onMounted, defineEmits, onBeforeUnmount, reactive } from 'vue'
import { userFinanceJoins, userFinanceRepeat } from "@/api/api"
import OrderDetail from './orderDetail.vue'
import { formatDate, formatPrice } from '@/api/kit';
const isDark = ref(0)
const emits = defineEmits(['goBlack'])
const activeTab = ref(1)
const orderDetailShow = ref(false)
const scrollContainer = ref()
const list = ref([])
const detailsRow = ref()
const total = ref(0)
const showflRow = ref()
const showfl = ref(false)
const currentDate = ref();
const minDate = ref(new Date())
const maxDate = ref(new Date(2025, 5, 1))
const prams = reactive({
  page: 0,
  size: 10
})
onMounted(() => {
  if (localStorage.getItem('isDark')) {
    isDark.value = localStorage.getItem('isDark')
  }
  getuserFinanceJoins()
  scrollContainer.value.addEventListener('scroll', handleScroll);
})
function showVantag(time){
  const currentTime = new Date().getTime();
  return time*1000 < currentTime;
}
const tapFL = (item) => {
  showfl.value = true
  showflRow.value = item
  const date = new Date(item.endAt * 1000);
  const year = date.getFullYear();
  const month = date.getMonth()
  const day = date.getDate();

  maxDate.value = new Date(year, month, day)
}
function timestampToDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
const goBlack = () => {
  emits('goBlack')
}
const tapSelectTab = (e) => {
  activeTab.value = e

}
const lookDetail = (item) => {
  orderDetailShow.value = true
  detailsRow.value = item
}
const closeOrderDetails = () => {
  orderDetailShow.value = false
}
onBeforeUnmount(() => {
  if (scrollContainer.value) {
    scrollContainer.value.removeEventListener('scroll', handleScroll);
  }
})
const handleScroll = () => {
  if (scrollContainer.value.scrollHeight - scrollContainer.value.scrollTop === scrollContainer.value.clientHeight) {
    console.log('滚动条到底部了');
    if (total.value > list.value.length) {
      prams.page += 1
      getuserFinanceJoins()
    }
  }
}
const getuserFinanceJoins = () => {
  userFinanceJoins(prams).then(res => {
    if (res&& res.list && res.list.length > 0) {
      list.value = [...res.list, ...list.value]
      total.value = res.total
    }

  })
}
const confirm = () => { //确认复利
  console.log(currentDate.value)
  if (!currentDate.value) {
    showToast(i18n.global.t('xzflsj'));
    return
  }
  let time = `${currentDate.value[0]}/${currentDate.value[1]}/${currentDate.value[2]}`
  let prams = {
    financeCode: showflRow.value.code,
    repeatEndAt: Math.round(new Date(time).getTime() / 1000)
  }
  // 掉接口
  userFinanceRepeat(prams).then(res => {
    if (res &&res.code==10000) {
      list.value = []
      showToast(i18n.global.t('sqcg'))
      showfl.value = false
      getuserFinanceJoins()
    }else if(res && res.code==20000){
      showToast(res.message);
    }

  })

}
</script>
<style scoped lang="less">
.orderhistoryModel {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  background: var(--backgroud);
  left: 0;
  padding: 10px;
  height: 100vh;
  overflow: auto;

  .headerTitle {
    width: 100%;
    text-align: center;
    height: 28px;
    line-height: 28px;
    color: var(--textColor);
    position: relative;
  }

  .goBlack {
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    width: 20px;
    height: 20px;
    position: absolute;

  }

  .orderSelectTab {
    display: flex;

    .tab {
      margin-right: 20px;
    }

    .activeTab {
      color: var(--activeTabbarText);
      position: relative;
    }

    .activeTab:after {
      content: '';
      position: absolute;
      width: 25px;
      border-radius: 5px;
      height: 3px;
      background: var(--activeAfterBg);
      bottom: -10px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  .orderScroll {
    overflow: auto;
    margin-top: 20px;
    height: calc(100vh - 100px);
  }

  .day {
    margin-top: 10px;
    text-align: center;
    align-items: center;
    align-content: center;
    border-radius: 50%;
    color: rgb(109, 103, 103);
    font-weight: bold;
    font-size: 18px;
    background-color: #f0b90b;
    height: 40px;
    width: 40px;
    overflow: hidden;
  }

  .orderModel {
    display: flex;
    position: relative;
    background: var(--homeCardBg);
    padding: 15px 15px 25px;
    border-radius: 5px;
    margin-bottom: 15px;

    .detail {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      .leftText {
        font-size: 12px;
        line-height: 12px;
      }

      .rightText {
        font-size: 13px;
        color: var(--lcitemTitle);
      }
    }


  }

  .gmlcje {
    position: absolute;
    right: 15px;
    top: 75px;
    font-size: 13px;
  }

  .flBTN {
    position: absolute;
    right: 15px;
    font-size: 13px;
    color: #5fe262;
    padding: 3px 12px;
    border-radius: 3px;
    top: 80%;
    transform: translate(0, -50%);
    // color: var(--tradeGL);
  }
}
.tagdanger{
  font-size: 12px ;
  color: rgba(241, 100, 75, 0.928);
  padding: 2px 5px;
  margin-left: 15px;
  display: inline-block;
  border-radius: 2px;
  position: absolute;
    right: 15px;
    font-size: 13px;
}
</style>