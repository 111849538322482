import moment from "moment";
import _ from "lodash";

export const SET_STATE = function (state: any, payload: any) {
  for (const p in payload) {
    const _state = state[p];
    if (Array.isArray(_state)) {
      state[p] = JSON.parse(JSON.stringify(payload[p]));
    } else if (typeof _state === "object") {
      if (payload[p]) {
        const obj = { ..._state, ...payload[p] };
        state[p] = JSON.parse(JSON.stringify(obj));
      } else {
        state[p] = JSON.parse(JSON.stringify(payload[p]));
      }
    } else {
      state[p] = JSON.parse(JSON.stringify(payload[p]));
    }
  }
};

export const formatAddress = (val: string, defaultValue = "") => {
  let a = "";
  if (val) {
    a += val.slice(0, 5);
    a += "...";
    a += val.slice(-5);
  } else {
    a = defaultValue;
  }
  return a;
};

export const formatPrice = (val: number, len = 8) => {
  if (!val || val == 0) {
    return 0;
  }
  return Number(val.toFixed(len));
};

export const formatDate = (val: number, format = "YYYY-MM-DD HH:mm:ss") => {
  if (val) {
    return moment.unix(val).format(format);
  }
  return "-";
};

export function formatAmount(val = 0, u = 2) {
  return _.floor(val, u).toFixed(u);
}

export function getIco(name: string) {
  if (!name) {
    return;
  }
  if (name == "XAUUSD") {
    return "https://app.eestatics.com/upload/2024/0112/AbIK5u1705045929484.png";
  } else if (name == "XAGUSD") {
    return "https://app.eestatics.com/upload/2024/0112/gjE3Ap1705045929956.png";
  } else if (name == "WTICOUSD") {
    return "https://app.eestatics.com/upload/2024/0112/Bjz3Xj1705045929469.png";
  }else if (name =="SUGARUSD"){
    return "https://s3-symbol-logo.tradingview.com/commodity/sugar--big.svg";
  }
  var url =
    "https://logo.twelvedata.com/forex/" +
    name.substring(0, name.length - 3).toLowerCase() +
    ".png";
  console.log(url);
  return url;
}

export function getSymbolDecimal(name: string) {
  switch (name) {
    case "XAUUSD":
      return 3;
    case "JPYUSD":
      return 8;
    case "EURUSD":
      return 5;
    case "CHFUSD":
      return 8;
    case "CADUSD":
      return 5;
    case "AUDUSD":
      return 5;
    case "NZDUSD":
      return 5;
    case "GBPUSD":
      return 5;
    default:
      return 5;
  }
}

export function isTradeRest(){
 
  var currenct  = new Date()
    if (currenct.getDay() == 6 || currenct.getDay() == 0 ){
    return true
  }
  return false
}