<template>
    <div class="orderDetailModel">
        <div class="headerTitle" >
            <img src="../../assets/fanhuijiantou.png" class="goBlack" alt="" v-if="isDark==1" @click="goBlack">
            <img src="../../assets/fanhuijiantou1.png" class="goBlack" alt="" v-if="isDark==0"  @click="goBlack">
            {{ $t("detail") }}
        </div>
       
        <div class="profitable" v-if="props.detailsRow">
            <div class="m_b_10 font12">{{$t('ljsy')}}</div>
            <div class="rise m_b_10 font15">{{props.detailsRow.totalAwardAmount}}</div>
            <!-- <div class="drop m_b_10 font15"> -21.5%</div> -->
        </div>
        <div class="DetailModel" v-if="props.detailsRow">
            <div class="detail">
                <div class="leftText">{{ $t('lcts') }}</div>
                <div class="rightText">{{ props.detailsRow.financeDay }}</div>
            </div>
            <div class="detail">
                <div class="leftText">{{ $t('lcll') }}</div>
                <div class="rightText">{{ props.detailsRow.financeRate}} %</div>
            </div>
            <!-- <div class="detail">
                <div class="leftText">{{ $t('jyID') }}</div>
                <div class="rightText">154823001548715 
                    <img src="../../assets/fuzhi.png" class="copyIcon" alt="" v-if="isDark==1" @click="copyId">
                    <img src="../../assets/fuzhi1.png" class="copyIcon" alt=""  v-if="isDark==0" @click="copyId">
                </div>
            </div> -->
            <div class="detail">
                <div class="leftText">{{ $t('lccpje') }}</div>
                <div class="rightText">{{ props.detailsRow.financeAmount }}</div>
            </div>
            <div class="detail">
                <div class="leftText">{{ $t('cyje') }}</div>
                <div class="rightText">{{props.detailsRow.amount}}</div>
            </div>
          
            <!-- <div class="detail">
                <div class="leftText">{{ $t('ckcode') }}</div>
                <div class="rightText">{{props.detailsRow.financeCode}}</div>
            </div> -->
            <div class="detail">
                <div class="leftText">{{ $t('kssj') }}</div>
                <div class="rightText">{{timestampToDate(props.detailsRow.startAt*1000)}}</div>
            </div>
            <div class="detail">
                <div class="leftText">{{ $t('jssj') }}</div>
                <div class="rightText">{{timestampToDate(props.detailsRow.endAt*1000)}}</div>
            </div>
            <div class="detail">
                <div class="leftText">{{ $t('sfhdlc') }}</div>
                <div class="rightText">{{props.detailsRow.isActivity==2?'Yes':'No'}}</div>
            </div>
            <div class="detail">
                <div class="leftText">{{ $t('sffl') }}</div>
                <div class="rightText">{{props.detailsRow.reptendat?$t('fl'):''}}</div>
            </div>
            <!-- <div class="detail">
                <div class="leftText">{{ $t('fx') }}</div>
                <div class="rightText" style="color:#48e64c">{{ $t('kz') }}</div>
                <div class="rightText" style="color:#f25a23">{{ $t('kd') }}</div>
            </div> -->
          
            
        </div>
       
    </div>
</template>
<script setup>
import { ref, onMounted ,defineProps,defineEmits} from 'vue'
import { useRouter  } from 'vue-router'
import { showToast } from 'vant';
const props = defineProps(['detailsRow'])
const isDark = ref(0)
const router = useRouter()
const emits= defineEmits(['goBlack'])
const activebtn = ref(2)
onMounted(()=>{
    if(localStorage.getItem('isDark')){
      isDark.value = localStorage.getItem('isDark')
    }
})
function timestampToDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
const goBlack = ()=>{
    emits('goBlack')
}
const copyId = ()=>{
    try {
      const text = '复制到内容'
      // 添加一个input元素放置需要的文本内容
      const input = document.createElement('input')
      input.value = text
      document.body.appendChild(input)
      // 选中并复制文本到剪切板
      input.select()
      document.execCommand('copy')
      // 移除input元素
      document.body.removeChild(input)
      showToast('已复制到剪切板')

    } catch (error) {
      console.log(error)
      showToast('复制失败');
    }
}
</script>
<style scoped lang="less">
.orderDetailModel {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: var(--backgroud);
    left: 0;
    padding: 10px;
    height: 100vh;
    overflow: auto;
    .headerTitle {
        width: 100%;
        text-align: center;
        height: 28px;
        line-height: 28px;
        color: var( --textColor);
        position: relative;
    }
    .goBlack{
        top:50%;
        transform: translate(0, -50%);
         left: 10px;
        width: 20px;
        height: 20px;
        position: absolute;

    }
    .profitable {
        background: var(--homeCardBg);
        border-radius: 5px;
        padding: 15px 0 5px;
        text-align: center;
    }

    .DetailModel {
        position: relative;
        padding: 15px 15px 25px;
        border-radius: 5px;
        .detail{
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .leftText{
                font-size: 12px;
                line-height: 12px;
            }
            .rightText{
                font-size: 13px;
                color: var(--lcitemTitle);
            }
            .copyIcon{
                width: 15px;
                height: 15px;
            }
        }
        
    }

}


</style>