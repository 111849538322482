<template>
    <div class="orderhistoryModel">
       
        <div class="headerTitle" >
            <img src="../../assets/fanhuijiantou.png" class="goBlack" alt="" v-if="isDark==1" @click="goBlack">
            <img src="../../assets/fanhuijiantou1.png" class="goBlack" alt="" v-if="isDark==0"  @click="goBlack">
            {{ $t("tz") }}
        </div>
      
          <div class="orderScroll" ref="scrollContainer">
            <div class="orderModel" v-for="(item,i) in props.NoticesList" :key="i"  >
                <div class="  font14" style="color: var(--lcitemTitle);">{{item.content}}</div>
            </div>
            <div class="noData " v-if="props.NoticesList.length==0">
               {{$t('nodata')}}
            </div>
          </div>
    </div>
</template>
<script setup>
import { ref, onMounted ,defineProps,defineEmits,onBeforeUnmount, reactive } from 'vue'
const props = defineProps(['NoticesList'])
const isDark = ref(0)
const emits= defineEmits(['goBlack'])
const activeTab = ref(1)
const orderDetailShow = ref(false)
const scrollContainer = ref()
const list = ref([])
const total = ref(0)
const prams = reactive({
    page:0,
    size:10
})
onMounted(()=>{
    if(localStorage.getItem('isDark')){
      isDark.value = localStorage.getItem('isDark')
    }
})
function timestampToDate(timestamp) {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
const goBlack = ()=>{
    emits('goBlack')
}



</script>
<style scoped lang="less">
.orderhistoryModel {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    background: var(--backgroud);
    left: 0;
    padding: 10px;
    height: 100vh;
    overflow: auto;
    .headerTitle {
        width: 100%;
        text-align: center;
        height: 28px;
        line-height: 28px;
        color: var( --textColor);
        position: relative;
    }
    .goBlack{
        top:50%;
        transform: translate(0, -50%);
         left: 10px;
        width: 20px;
        height: 20px;
        position: absolute;

    }
    .orderSelectTab {
    display: flex;

    .tab {
      margin-right: 20px;
    }
    .activeTab {
      color: var(--activeTabbarText);
      position: relative;
    }

    .activeTab:after {
      content: '';
      position: absolute;
      width: 25px;
      border-radius: 5px;
      height: 3px;
      background: var(--activeAfterBg);
      bottom: -10px;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .orderScroll{
    overflow: auto;
    margin-top: 20px;
    height: calc(100vh - 100px);
  }
    .orderModel {
        position: relative;
        background: var(--homeCardBg);
        padding: 15px 15px ;
        border-radius: 5px;
        margin-bottom: 15px;
        .detail{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            .leftText{
                font-size: 12px;
                line-height: 12px;
            }
            .rightText{
                font-size: 13px;
                color: var(--lcitemTitle);
            }
        }
       
     
    }

}


</style>