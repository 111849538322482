<template>
    <!-- props.show -->
    <div v-if=" props.show" class="loadingModel">
        <div class="loading-circle">
        </div>
        <img class="logoLoading" src="@/assets/logo.png" alt="">
        <!-- <van-loading type="spinner" color="#1989fa" class=""/> -->
    </div>
</template>
<script setup>
    import { Loading } from 'vant';
    import {ref ,defineProps} from 'vue'
    const props = defineProps(['show'])
</script>
<style scoped>


.loadingModel{
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
    background: #adadadc2;
    display:flex;
    align-items: center;
    justify-content: center;
}
.logoLoading{
    position: absolute;
    width: 100px;
}
.loading-circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #3498db;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>