
import serviceAxios from "./request";



//获取首页ticker价格
export const ticker = (params:object) => {
  return serviceAxios({
    url: "/ticker",
    method: "get",
    params,
  });
};
// 消息
export const notices = (params:object) => {
  return serviceAxios({
    url: "/notices",
    method: "get",
    params,
  });
};
//用户信息
export const userInfo = (params:object) => {
  return serviceAxios({ 
    url: "/user/info",
    method: "get",
    params,
  });
};
// 理财列表
export const finances = (params:object) => {
  return serviceAxios({
    url: "/finances",
    method: "get",
    params,
  });
};
// 理财活动列表
export const financeactivitys = (params:object) => {
  return serviceAxios({
    url: "/financeactivitys",
    method: "get",
    params,
  });
};
// ?kind=1&size=10&page=0 用户钱包记录
export const userRecords = (params:object) => {
  return serviceAxios({
    url: "/user/records",
    method: "get",
    params,
  });
};

// ?size=10&page=0 用户参与理财记录
export const userFinanceJoins = (params:object) => {
    return serviceAxios({
      url: "/user/finance/joins",
      method: "get",
      params,
    });
  };
  //获取竞猜配置
export const guessconfig = (params:object) => {
    return serviceAxios({
      url: "/guess/config",
      method: "get",
      params,
    });
  };
// 申请理财
export const financejoin = (data:object) => {
  return serviceAxios({
    url: "/user/finance/join",
    method: "post",
    data,
  });
};

export const userTake = (data:object) => {
  return serviceAxios({
    url: "/user/take",
    method: "post",
    data,
  });
};
// 下单
export const guessBet = (data:object) => {
  return serviceAxios({
    url: "/guess/bet",
    method: "post",
    data,
  });
};
// 我的竞猜
export const guessrecords = (params:object) => {
  return serviceAxios({
    url: "/guess/records",
    method: "get",
    params,
  });
};
//团队竞猜
export const guessteamrecords = (params:object) => {
  return serviceAxios({
    url: "/guess/team/records",
    method: "get",
    params,
  });
};


// 团队交易
//团队下方列表
export const userTeam = (params:object) => {
  return serviceAxios({
    url: "/user/team/records",
    method: "get",
    params,
  });
};
export const Teamstatic = (params:object) => { //团队统计
  return serviceAxios({
    url: "/user/team/static",
    method: "get",
    params,
  });
};

export const guesstoday = (params:object) => { //我的今日竞猜统计
  return serviceAxios({
    url: "/guess/today",
    method: "get",
    params,
  });
};
export const guessteamtoday = (params:object) => { //团队的今日竞猜统计
  return serviceAxios({
    url: "/guess/team/today",
    method: "get",
    params,
  });
};
export const kline = (symbol:string,period:string) => { //kline初始数据
  return serviceAxios({
    url: "/kline",
    method: "get",
    params:{symbol:symbol,period:period},
  });
};
export const userFinanceRepeat = (data:Object) => { //复利
  return serviceAxios({
    url: "/user/finance/repeat",
    method: "post",
    data
  });
};

export const shareUserTeam = (data:Object) => { //分享我的直推
  return serviceAxios({
    url: "/user/share/records",
    method: "get",
    params:data
  });
};
export const bscSpeed = (data:Object) => { //加速
  return serviceAxios({
    url: "/block/bsc/speed",
    method: "get",
    params:data
  });
};
