import { ERROR_INFO } from './config'
import { userStore } from '../../stores/modules/user'
import { NETWORK } from './config'
import { showDialog } from 'vant';
import i18n from '@/i18/index';
import Contract from './contract';

export default class Ethereum {
    static ether = (window as any).ethereum
    static address = ''
    static _isConnected = false

    static get isInstall() {
        if (!this.ether) {
            //  window.alert('请使用钱包浏览器')
            // showDialog({
            //     confirmButtonText: i18n.global.t('confirm'),
            //     message: i18n.global.t('pleaseWalletBrowser'),
            // }).then(() => {
            //     // on close
            // });
            return false
        }
        return true
    }

    static async initEvent() {
        if (this.isInstall) {
            await this.disconnect()
            await this.message()
            await this.connect()
            await this.chainChanged()
            await this.accountsChanged()
        }
    }

    static async disconnect() {
        this.ether.on('disconnect', () => {
            userStore().updateAddress("");
        })
    }

    static async accountsChanged() {
        this.ether.on('accountsChanged', (e:any) => {
            if (e.length > 0) {
                this.setAddress()
                userStore().updateSign("")
                Contract.signMessage()
             }
            if (!e.length) {
                userStore().updateAddress("");
                userStore().updateSign("")
                // window.alert(ERROR_INFO.ACCOUNT_DIS)
                showDialog({
                    message: `${ERROR_INFO.ACCOUNT_DIS}`,
                }).then(() => {
                    // on close
                });
            }
            // this.ether.removeListener('accountsChanged', () => {
            //     userStore().updateAddress("");
            // })
        })
    }

    static async chainChanged() {
        this.ether.on('chainChanged', () => {
            if ((window as any).ethereum.chainId !== NETWORK.chainId) {
                this.switchChain()
            }
        })
    }

    static async message() {
        this.ether.on('message', () => {
        })
    }

    static async switchChain() {
        try {

            let { chainId } = NETWORK
            await (window as any).ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId }]
            })
        } catch (switchError:any) {
            console.log(switchError.code)
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902 || switchError.code === -32603) {
                await this.addChain(NETWORK)
            }
            console.log('switchError', switchError)
        }
    }

    static async addChain(data:any) {
        this.ether.request({
            method: 'wallet_addEthereumChain',
            params: [data]
        })
    }

    static async connect() {
        this.ether.on('connect', () => {
            if ((window as any).ethereum.chainId !== NETWORK.chainId) {
                this.switchChain()
            }
        })
    }

    static async send() {
        try {
            await this.ether.request({
                method: 'eth_requestAccounts'
            })
            await this.setAddress()
            return true
        } catch (e:any) {
            showDialog({
                message: `${(ERROR_INFO[e.code.toString()] || '系統錯誤')}`,
            }).then(() => {
                // on close
            });

            return false
        }
    }

    static async setAddress() {
        console.warn("setAddress")
        const address = (window as any).ethereum.selectedAddress
        if (address) {
            await userStore().updateAddress(address);
         }
    }

    static async init() {
        await this.initEvent()
        console.warn("init")
        if (this.ether.selectedAddress) {
            await this.setAddress()
            await this.switchChain()
        } else {
            await this.send()
        }
    }

    static async destroy() {
        // this.provider = null
    }

    static async destroyed() {
    }
}
