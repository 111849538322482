import { createStore } from 'vuex'

export default createStore({
  state: {
    isDark:0,
    activeTabKey:1,
    path:'',
    userInfo:''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
