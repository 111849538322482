<template>
    <div class="finacialModel">
        <!-- <img src="../../assets/fanhuijiantou.png" class="goBlack" alt="" v-if="isDark==1" @click="goBlack">
        <img src="../../assets/fanhuijiantou1.png" class="goBlack" alt="" v-if="isDark==0"  @click="goBlack">
       
        <div class="FixedBTN">
             <div :class="activebtn == 1 ? 'activebtndklc' : 'btndklc'" @click="tapLCDK(1)">{{ $t("dk") }}</div> 
            <div :class="activebtn == 2 ? 'activebtndklc' : 'btndklc'" @click="tapLCDK(2)">{{ $t("ailc") }}</div>
        </div> -->
        
        <div class="headerTitle">
            <img src="../../assets/fanhuijiantou.png" class="goBlack" alt="" v-if="isDark == 1" @click="goBlack">
            <img src="../../assets/fanhuijiantou1.png" class="goBlack" alt="" v-if="isDark == 0" @click="goBlack">
           
            {{ $t("ailc") }}
            <img src="../../assets/dingdanjilu.png" class="orderhistory" alt="" v-if="isDark==1" @click="orderhistory">
        <img src="../../assets/dingdanjilu1.png" class="orderhistory" alt="" v-if="isDark==0"  @click="orderhistory">
        </div>
        <!-- <div style="height: 30px;"></div> -->
        <!-- <div class="profitModel">
            <div class="font12 m_b_10">
                {{ $t("profit") }}
            </div>
            <div class="font14 m_b_10 " style="color: var(--lcitemTitle);">872</div>
            <div class="font12 m_b_10">
                {{ $t("jrprofit") }}<span class="rise m_l_10">99</span>
            </div>
            <div class="tgdd" @click="orderhistory">{{ $t('tgdd') }}</div>
        </div> -->
        <!-- <div class="advertising">
            人工智能图片占位
        </div> -->
        <div class="orderSelectTab font12">
            <div @click="tapSelectTab(1)" :class="activeTab == 1 ? 'activeTab tab' : 'tab'">{{ $t("cp") }}</div>
            <div @click="tapSelectTab(2)" :class="activeTab == 2 ? 'activeTab tab' : 'tab'">{{ $t("hd") }}</div>
        </div>

        <div class="ListModel">
            <div class="ItemList" v-for="(item, i) in dataList" @click="tapItem(item)" :key="i">
                <div class="item">
                    <!-- <img class="img" src="@/assets/gold--big.svg" alt=""> -->
                   <span class="day">{{ item.day }}</span> 
                    <div>
                        <div class="title">{{ formatPrice(item.minAmount,2) }}$  </div>
                        <div class="font12 m_l_10 m_b_10">
                            {{ $t('gzrsy') }}
                            <span class="font13 m_l_10 m_b_10 rise">{{ formatPrice(item.rate*100,2) }} %</span>
                        </div>
                        <div class="font12 m_l_10 m_b_10">
                            {{ $t('yjrsy') }}
                            <span class="font13 m_l_10 m_b_10 rise">{{ formatPrice(item.rate*item.minAmount,2) }}$</span>
                        </div>
                        <div class="font10 m_l_10">
                            {{ $t('cyzq') }} <span class=" m_l_10 m_b_10 ">{{ item.day }}</span>{{$t('day')}}</div>
                        
                    </div>
                </div>
                <!-- <div class="broadcast">
                    12分钟前 183****45 购买了该产品
                </div> -->
                <div class="lookBtn">
                    <img src="../../assets/jiantouxi.png" class="icon" alt="" v-if="isDark == 1">
                    <img src="../../assets/jiantouxi1.png" class="icon" alt="" v-if="isDark == 0">
                </div>
            </div>
            <div class="noData " v-if="dataList.length==0">
               {{$t('nodata')}}
            </div>
        </div>
        <ProductsDetail  v-if="showProductsDetail" @goBlack="closeProductsDetail"
            :Details="lcDetail"></ProductsDetail>
        <Orderhistory v-if="showOrderhistory" @goBlack="closeOrderhistory"></Orderhistory>
    </div>
</template>
<script setup>
import { finances ,financeactivitys} from "@/api/api"
import { ref, onMounted, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import ProductsDetail from './productsDetail.vue'
import Orderhistory from './orderhistory.vue'
import { formatPrice } from "@/api/kit"
const router = useRouter()
const emits = defineEmits(['goBlack'])
const isDark = ref(0)
const activebtn = ref(2)
const showProductsDetail = ref(false)
const showOrderhistory = ref(false)
const dataList = ref([])
const lcDetail = ref({})
const activeTab = ref(1)
onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    if (localStorage.getItem('isDark')) {
        isDark.value = localStorage.getItem('isDark')
    }
    getFinances()
})
// const ProductsDetailchange = () => {
//     debugger
//     showProductsDetail.value = false
//     getFinances()
// }
const tapSelectTab = (e) => {
    activeTab.value = e
    dataList.value =[]
    getFinances()
}
const getFinances = () => {
    if (activeTab.value == 1) {
        finances().then(res => {
            if (res && res.list) {
                dataList.value = res.list
            }

        })
    } else {
        financeactivitys().then(res => {
            if (res && res.list) {
                dataList.value = res.list
            }

        })
    }

}
const tapLCDK = (e) => {
    activebtn.value = e
}
const goBlack = () => {
    emits('goBlack')
}
const orderhistory = () => {
    showOrderhistory.value = true
}
const closeProductsDetail = () => {
    debugger
    showProductsDetail.value = false
    getFinances()
}
const closeOrderhistory = () => {
    showOrderhistory.value = false
}
const tapItem = (item) => {
    lcDetail.value = item
    showProductsDetail.value = true
}
const handleScroll = () => {
    const clientHeight = document.documentElement.clientHeight;
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop + clientHeight >= scrollHeight) {
        console.log('Reached bottom');
        getFinances()
    }
};
</script>
<style scoped lang="less">
.finacialModel {
    height: calc(100%);
    background: var(--backgroud);
    padding: 10px;
    box-sizing: border-box;

    .goBlack {
        top: 50%;
        transform: translate(0, -50%);
        left: 10px;
        width: 20px;
        height: 20px;
        position: absolute;

    }

    .headerTitle {
        width: 100%;
        text-align: center;
        height: 28px;
        line-height: 28px;
        color: var(--textColor);
        position: relative;
    }

    .orderhistory {
        top: 50%;
        transform: translate(0, -50%);
        right: 10px;
        width: 15px;
        height: 15px;
        position: absolute;

    }

    .FixedBTN {
        justify-content: space-between;
        position: fixed;
        position: fixed;
        left: 50%;
        display: flex;
        align-items: center;
        transform: translate(-50%, 10px);
        font-size: 13px;
        justify-content: space-between;
        border-radius: 20px;
        background: var(--homeCardBg);

    }

    ;

    .activebtndklc {
        padding: 0 10px;
        width: 80px;
        text-align: center;
        background: var(--LCDKACTIVEBG);
        border-radius: 20px;
        color: var(--LCDKACTIVETEXT);
        height: 28px;
        line-height: 28px;
    }

    .btndklc {
        padding: 0 10px;
        width: 80px;
        text-align: center;
        height: 28px;
        line-height: 28px;
    }

    .profitModel {
        position: relative;
        background: var(--homeCardBg);
        padding: 15px 15px 40px;
        border-radius: 5px;

        .tgdd {
            position: absolute;
            right: 15px;
            border: 1px solid var(--activeAfterBg);
            color: var(--activeAfterBg);
            border-radius: 20px;
            height: 27px;
            text-align: center;
            line-height: 27px;
            font-size: 13px;
            padding: 0 10px;

        }
    }

    .advertising {
        margin: 15px 0;
        height: 80px;
        background: var(--homeCardBg);
        border-radius: 5px;
    }

    .ListModel {
        height: calc(100vh - 70px);
        overflow: auto;

        .ItemList {
            background: var(--homeCardBg);
            border-radius: 5px;
            margin: 15px 0;
            padding: 10px;
            position: relative;
              

            .img {
                margin-right: 10px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            .day{
                margin-top: 10px;
                 text-align: center;
                 align-items: center;
                 align-content: center;
                border-radius: 50%;
                color: rgb(109, 103, 103);
                font-weight: bold;
                font-size: 18px;
                background-color: #f0b90b;
                height: 40px;
                width: 40px;
                overflow: hidden;
            }

            .item {
                display: flex;
            }

            .title {
                color: var(--lcitemTitle);
                margin-bottom: 10px;
                padding: 0 10px;
            }

            .tagModel {
                padding: 0 10px;
                display: flex;
                margin-bottom: 10px;
                flex-wrap: wrap;
            }

            .tag {
                background: var(--lcitemTagBG);
                color: var(--lcitemTagText);
                margin-right: 10px;
                border-radius: 10px;
                padding: 2px 8px;
                height: 15px;
                line-height: 15px;
                font-size: 11px;

            }

            .broadcast {
                border-radius: 0 0 5px 5px;
                font-size: 12px;
                padding: 5px 10px;
                background: var(--lcbroadcastBG);
            }

            .lookBtn {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translate(-50%, -50%);

                .icon {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .orderSelectTab {
        display: flex;
        margin-top: 10px;
        margin-bottom: 5px;
        .tab {
            margin-right: 20px;
        }

        .activeTab {
            color: var(--activeTabbarText);
            position: relative;
        }

        .activeTab:after {
            content: '';
            position: absolute;
            width: 25px;
            border-radius: 5px;
            height: 3px;
            background: var(--activeAfterBg);
            bottom: -10px;
            left: 50%;
            transform: translate(-50%);
        }
    }

}</style>