import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import state from '../stores/index'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
 
  {
    path: '/share',
    name: 'share',
    component: () => import('../views/share.vue')
  },
  {
    path: '/trade',
    name: 'trade',
  
    component: () => import( '../views/trade.vue')
  },
  {
    path: '/team',
    name: 'team',
   
    component: () => import('../views/team.vue')
  },
  {
    path: '/asset',
    name: 'asset',
   
    component: () => import('../views/asset.vue')
  },
  {
    path: '/updatePassWord',
    name: 'updatePassWord',
    component: () => import('../views/updatePassword.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  console.log('Global route change:', to);
  localStorage.setItem('path', to.path)
  state.state.path =to.path 
  next();
});
export default router
