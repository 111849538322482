import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './stores'
import '@/assets/css/theme.less';
import i18n from '@/i18'
import vant from 'vant';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import 'vant/lib/index.css';
import Loading from '@/components/loading.vue'

const app =createApp(App)
app.component('Loading', Loading)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);

app.use(vant).use(i18n).use(store).use(pinia).use(router).mount('#app')
